<template>
  <select
    class="base__select"
    v-bind="{
      ...$attrs,
      onChange: ($event) => {
        $emit('update:modelValue', $event.target.value);
      },
    }"
  >
    <option v-if="title" value="">{{ title }}</option>
    <option
      :key="key"
      v-for="(option, key) in options"
      :value="option"
      :selected="option === modelValue"
    >
      {{ option }}
    </option>
  </select>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseSelect",
  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
    options: {
      type: Array,
      required: true,
    },
  },
});
</script>

<style scoped>
input {
}
</style>
