import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-15e8d182"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  value: ""
}
const _hoisted_2 = ["value", "selected"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("select", _mergeProps({ class: "base__select" }, {
      ..._ctx.$attrs,
      onChange: ($event) => {
        _ctx.$emit('update:modelValue', $event.target.value);
      },
    }), [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("option", _hoisted_1, _toDisplayString(_ctx.title), 1))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, key) => {
      return (_openBlock(), _createElementBlock("option", {
        key: key,
        value: option,
        selected: option === _ctx.modelValue
      }, _toDisplayString(option), 9, _hoisted_2))
    }), 128))
  ], 16))
}